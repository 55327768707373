import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./App.scss";
import gifs from "./assets/gifs.json";

const getRandomDelay = () => (Math.floor(Math.random() * 2) + 3) * 1000;

function App() {
  const [gridSize, setGridSize] = useState<number>(4);
  const [cycle, setCycle] = useState<number>(0);

  const generateNewGrid = useCallback(() => {
    const newGridSize = Math.floor(Math.random() * 4) + 1;
    setGridSize(newGridSize);
    setCycle((cycle) => cycle + 1);
    window.setTimeout(generateNewGrid, getRandomDelay());
  }, [setCycle]);

  useEffect(() => {
    generateNewGrid();
  }, [generateNewGrid]);

  const generateRows = useMemo((): JSX.Element[] => {
    const getGifDim = (): CSSProperties => {
      return {
        maxWidth: `${100 / gridSize}vw`,
        maxHeight: `${100 / gridSize}vh`,
        width: `${100 / gridSize}vw`,
        height: `${100 / gridSize}vh`,
        objectFit: "fill",
      };
    };

    const rows = new Array<JSX.Element>();
    const gridSet = new Set<number>();
    for (let i = 0; i < gridSize; i++) {
      const cells = new Array<JSX.Element>();
      for (let j = 0; j < gridSize; j++) {
        let randomIndex = Math.floor(Math.random() * gifs.length);
        while (gridSet.has(randomIndex) && gridSet.size < gifs.length) {
          randomIndex = (randomIndex + 1) % gifs.length;
        }
        const gif = gifs[randomIndex];
        gridSet.add(randomIndex);
        cells.push(
          <img src={gif.src} alt={gif.description} style={getGifDim()} />
        );
      }
      rows.push(
        <div className="row" id={cycle + "-" + i}>
          {cells}
        </div>
      );
    }

    return rows;
  }, [gridSize, cycle]);

  return (
    <div className="App" style={{ backgroundColor: "#000" }}>
      <a
        href="https://valentincharoux.com/"
        target="_blank"
        className="watermark"
        rel="noreferrer"
      >
        <img src="./assets/logo.svg" alt="valentincharoux.com" />
      </a>
      {generateRows}
    </div>
  );
}

export default App;
